<template>
  <div id="app">
    <van-nav-bar
      v-if="!routeInfo.meta.hiddenHeadBar"
      :title="routeInfo.meta.title"
      :left-arrow="!routeInfo.meta.hiddenHeadIcon"
      fixed
      @click-left="handleArrowClick"
    >
      <template #right>
        <van-icon v-if="barIconName" :name="barIconName" size="0.52rem" @click="$refs.myView.handleIconClick" />
      </template>
    </van-nav-bar>
    <keep-alive :include="cachedViews">
      <router-view v-if="$route.meta.keepAlive" ref="myView" :style="{marginTop: routeInfo.meta.hiddenHeadBar ? '' : '1.17rem'}" @handleCachedViews="handleCachedViews" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" ref="myView" :style="{marginTop: routeInfo.meta.hiddenHeadBar ? '' : '1.17rem'}" @handleCachedViews="handleCachedViews" />
  </div>

</template>

<script>
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  data() {
    return {
      cachedViews: [],
      transitionName: '',
      routeInfo: '',
      customizeHandle: ['Sop', 'Device', 'ExceptionTrigger', 'Production', 'ProductionManage'] // 自定义导航栏后退图标的点击事件的页面
    }
  },
  computed: {
    ...mapGetters(['barIconName', 'token'])
  },
  watch: {
    $route: {
      handler(to, from) {
        this.routeInfo = to
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    const userData = localStorage.getItem('userData')
    const userRole = localStorage.getItem('userRole')
    const userRouters = localStorage.getItem('userRouters')
    if (userData) {
      this.$store.commit('SET_USER_DATA', JSON.parse(userData))
    }
    if (userRole) {
      this.$store.commit('SET_USER_ROLE', JSON.parse(userRole))
    }
    if (userRouters) {
      this.$store.commit('SET_USER_ROUTERS', JSON.parse(userRouters))
    }
    if (getToken()) {
      this.$store.commit('SET_TOKEN', getToken())
    }

    // 获取所有语言文件, 若有匹配系统语言的文件则使用，否则默认中文
    const langFiles = require.context('@/language/', false, /.js$/).keys().map(i => {
      return i.replace('./', '').split('.')[0]
    })
    const lan = navigator.language.includes('en') ? 'en-US' : navigator.language.includes('vi') ? 'vi-VN' : navigator.language
    this.$i18n.locale = langFiles.includes(lan) ? lan : 'zh-CN'
  },
  methods: {
    handleCachedViews(isAdd, value) {
      if (isAdd) {
        if (!this.cachedViews.includes(value)) {
          this.cachedViews.push(value)
        }
      } else {
        if (this.cachedViews.includes(value)) {
          this.cachedViews.splice(this.cachedViews.indexOf(value), 1)
        }
      }
    },
    handleArrowClick() {
      if (this.customizeHandle.includes(this.$route.name)) {
        this.$refs.myView.arrowClick()
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.2s;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

</style>

<style lang="scss">
#app {
  width: 100vw;
  overflow-x: hidden;
  font-family:"Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F3F6FA;
  min-height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
