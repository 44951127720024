// 获取所有语言文件
const langFiles = require.context('@/language/', false, /.js$/).keys().map(i => {
  return i.replace('./', '').split('.')[0]
})

// 若有对应语言文件则使用，否则用中文
const lang = langFiles.includes(navigator.language) ? require(`@/language/${[navigator.language]}.js`).default : require(`@/language/zh-CN.js`).default

export default {
  '401': lang.system['401'],
  '403': lang.system['403'],
  '404': lang.system['404'],
  'default': lang.system['default']
}
