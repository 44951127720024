import Vue from 'vue'

import {
  Loading,
  List,
  Button,
  NavBar,
  Form,
  Field,
  Toast,
  Cascader,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  TreeSelect,
  Popup,
  Icon,
  Badge,
  Picker,
  Search,
  Switch,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Step,
  Steps
} from 'vant'

Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Loading)
Vue.use(List)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Search)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Cascader)
Vue.use(TreeSelect)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Switch)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Cascader)
Vue.use(Step)
Vue.use(Steps)

Vue.prototype.$toast = Toast
