import Vue from 'vue'
import Vuex from 'vuex'
import { setToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userData: '',
    userRole: '',
    userRouters: '',
    barIconName: ''
  },
  getters: {
    token: state => state.token,
    userData: state => state.userData,
    userRouters: state => state.userRouters,
    barIconName: state => state.barIconName
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_USER_DATA: (state, data) => {
      state.userData = data
      localStorage.setItem('userData', JSON.stringify(data))
    },
    SET_USER_ROLE: (state, data) => {
      state.userRole = data
      localStorage.setItem('userRole', JSON.stringify(data))
    },
    SET_USER_ROUTERS: (state, data) => {
      state.userRouters = data
      console.log(data)
      localStorage.setItem('userRouters', JSON.stringify(data))
    },
    SET_BAR_ICON_NAME: (state, token) => {
      state.barIconName = token
    }
  },
  actions: {
  },
  modules: {
  }
})
