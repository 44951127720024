<template>
  <div class="home">
    <!--    个人信息-->
    <div class="user-info">
      <p>{{ currentTime.helloNotice }}, {{ userData.nickName }}</p>
      <p>{{ currentTime.date }} {{ currentTime.week }}</p>
      <div class="user-avatar">
        <img :src="userData.avatar || require('@/assets/img/home/profile.png')" alt="">
      </div>
    </div>
    <div v-if="topMenu.length > 0" class="top-menu">
      <div
        v-for="(item, index) in topMenu"
        :key="index"
        class="top-menu__item"
        :class="{long: topMenu.length === 1}"
        @click="$router.push('/' + item.path)"
      >
        <img :src="item.img" alt="">
        <p :style="{color: item.color}">
          {{ item.name }}<br>
          <span>{{ item.detail }}</span>
        </p>
        <van-icon v-if="topMenu.length === 1" name="arrow" />
      </div>
    </div>
    <!--    待办列表-->
    <div v-if="total > 0" class="remain-area">
      <div class="remain-head">{{ $t('homePage.todo') }}&nbsp;
        <div class="number-circle" :class="{small: total > 99}">{{ total }}</div>
        <span @click="checkAll">{{ $t('common.checkAll') }}  <van-icon name="arrow" /></span>
      </div>
      <div class="remain-list">
        <div
          v-for="(item, index) in remainList"
          :key="index"
          class="remain-item"
          :style="{ borderBottom: index === remainList.length - 1 ? 'none' : '' }"
          @click="checkDetail(item)"
        >
          <div class="circle" />
          <p :style="{maxWidth: item.deadLine < nowTime ? '6rem' : '7.7rem'}">{{ item.name }}</p>
          <status-icon v-if="item.deadLine < nowTime" :status="'5'" />
        </div>
      </div>
    </div>
    <!-- andon系统 -->
    <div class="business-system">
      <p class="business-head">{{ $t('homePage.andonSystem') }}</p>
      <div class="business-body">
        <template v-for="(item, index) in andonList">
          <div v-if="userRouters.includes(item.path)" :key="index" class="business-item" @click="jump(item.path)">
            <img :src="item.img" alt="">
            <p>{{ item.name }}</p>
          </div>
        </template>
      </div>
    </div>
    <!--    生产系统-->
    <div class="business-system">
      <p class="business-head">{{ $t('homePage.productionSystem') }}</p>
      <div class="business-body">
        <template v-for="(item, index) in businessList">
          <div v-if="userRouters.includes(item.path)" :key="index" class="business-item" @click="jump(item.path)">
            <img :src="item.img" alt="">
            <p>{{ item.name }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import { todoList } from '@/api/baseData/common'
import { mapState } from 'vuex'
import StatusIcon from '@/components/StatusIcon'

export default {
  name: 'HomeView',
  components: { StatusIcon },
  data() {
    return {
      currentTime: {
        date: '',
        week: '',
        helloNotice: ''
      },
      total: 0,
      nowTime: '',
      andonList: [
        {
          img: require('@/assets/img/andon/handle.png'),
          path: 'exceptionHandle'
        },
        {
          img: require('@/assets/img/andon/apply.png'),
          path: 'reasonApply'
        },
        {
          img: require('@/assets/img/andon/trigger.png'),
          path: 'exceptionTrigger'
        }
      ],
      businessList: [
        {
          img: require('@/assets/img/home/kanban.png'),
          path: 'kanban'
        },
        {
          img: require('@/assets/img/home/sop.png'),
          path: 'sop'
        },
        {
          img: require('@/assets/img/home/device.png'),
          path: 'device'
        },
        {
          img: require('@/assets/img/home/device.png'),
          path: 'deviceShutdown'
        },
        {
          img: require('@/assets/img/home/device.png'),
          path: 'mesControl'
        },
        {
          img: require('@/assets/img/home/device.png'),
          path: 'productionManage'
        }
      ],
      remainList: []
    }
  },
  computed: {
    ...mapState(['userData', 'userRouters', 'userRole']),
    topMenu() {
      const menu = [
        {
          name: this.$t('homePage.production'),
          img: require('@/assets/img/home/production.png'),
          path: 'production',
          detail: this.$t('homePage.checkAndOpen'),
          color: '#315DF1'
        },
        {
          name: this.$t('homePage.tpm'),
          img: require('@/assets/img/home/tpm.png'),
          path: 'tpm',
          detail: this.$t('homePage.deviceManage'),
          color: '#47B18C'
        }
      ]
      return menu.filter(i => this.userRouters.includes(i.path))
    }
  },
  created() {
    this.handleTime()
    this.handleList()
  },
  mounted() {
    this.nowTime = new Date().getTime()
    this.getTodoList()
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    }, 100)
  },
  methods: {
    // 处理菜单数组
    handleList() {
      // 动态组装系统名称
      this.andonList.forEach(i => {
        i.name = this.$t('andon.' + i.path)
      })
      this.businessList.forEach(i => {
        i.name = this.$t('homePage.' + i.path)
      })
    },
    // 处理时间
    handleTime() {
      const date = new Date()
      const month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)
      const hour = date.getHours()
      const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
      const weekList = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      this.currentTime.week = weekList[date.getDay()]
      this.currentTime.date = month + '-' + day
      if (hour >= 6 && hour < 12) {
        this.currentTime.helloNotice = '早上好'
      } else if (hour >= 12 && hour < 14) {
        this.currentTime.helloNotice = '中午好'
      } else if (hour >= 14 && hour < 18) {
        this.currentTime.helloNotice = '下午好'
      } else {
        this.currentTime.helloNotice = '晚上好'
      }
    },
    getTodoList() {
      todoList({ pageSize: 3, pageNum: 1, toUser: this.userData.id }).then(res => {
        if (res.code === 200 && res.data && res.data.items.length > 0) {
          this.remainList = res.data.items
          this.total = res.data.total
        }
      })
    },
    jump(path) {
      this.$router.push('/' + path)
    },
    checkAll() {
      this.$router.push('/todo')
    },
    checkDetail(data) {
      if (data.type === 'exception') {
        this.$router.push({
          path: '/exceptionDetail',
          query: {
            id: data.originalId
          }
        })
      } else if (data.type === 'exceptionReason') {
        this.$router.push({
          path: '/recordDetail',
          query: {
            id: data.originalId,
            isAudit: true
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 0 0.425rem 1rem;
  font-size: 0.32rem;
  text-align: left;
  background: url("@/assets/img/home/homeBg.png") no-repeat top;
  background-size: 100% auto;

  .user-info {
    position: relative;
    width: 100%;
    padding: 1rem 0.43rem 0.64rem;
    box-sizing: border-box;

    div {
      position: absolute;
      top: 0.95rem;
      right: 0;
      width: 1.07rem;
      height: 1.07rem;
      border-radius: 50%;
      border: 0.03rem solid #BFBFBF;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      margin: 0;
      color: #565656;
      font-size: 0.32rem;
    }

    p:nth-child(1) {
      font-size: 0.43rem;
      font-weight: bold;
      margin-bottom: 0.24rem;
    }
  }

  .top-menu {
    display: flex;
    justify-content: space-between;
    width: 9.15rem;
    height: 1.92rem;
    margin: 0 auto 0.32rem auto;

    .top-menu__item {
      display: inline-block;
      width: 4.4rem;
      height: 100%;
      box-sizing: border-box;
      padding: 0.43rem;
      background: #FFFFFF;
      border-radius: 0.21rem;

      img {
        display: inline-block;
        width: 1.07rem;
        height: 1.07rem;
      }

      p {
        display: inline-block;
        margin: 0 0 0 0.43rem;
        vertical-align: top;
        line-height: 0.55rem;
        font-size: 0.23rem;
        font-weight: bold;

        span {
          font-size: 0.22rem;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .top-menu__item.long {
      width: 100%;

      i {
        font-size: 0.37rem;
        float: right;
        line-height: 1.07rem;
        color: #999999;
      }
    }
  }

  .remain-area {
    margin-top: 0.32rem;
    padding: 0.43rem 0.43rem 0;
    background: #FFFFFF;
    border-radius: 0.11rem;

    .remain-head {
      position: relative;
      line-height: 0.43rem;
      font-size: 0.43rem;
      font-weight: bold;
      color: #262626;

      span {
        font-weight: 400;
        float: right;
        font-size: 14px;
        color: #999999;
      }

      .number-circle {
        position: absolute;
        top: -0.05rem;
        left: 1.8rem;
        width: 0.5rem;
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.37rem;
        background: #F16861;
        text-align: center;
        color: #FFFFFF;
        border-radius: 0.5rem;
        vertical-align: top;
        font-weight: 400;
      }

      .number-circle.small {
        font-size: 0.27rem;
      }
    }

    .remain-list {
      margin-top: 0.32rem;

      .remain-item {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #f2f2f2;
        overflow: hidden;
        padding: 0.32rem 0;
        font-size: 0.4rem;
        color: #565656;
        vertical-align: top;

        div, p {
          display: inline-block;
        }

        .circle {
          width: 0.24rem;
          height: 0.24rem;
          border-radius: 50%;
          background: #FFFFFF;
          border: 0.05rem solid #315DF1;
          margin-right: 0.3rem;
          box-sizing: border-box;
        }

        .status-icon {
          margin-left: 0.3rem;
        }

        p {
          margin: 0;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .business-system {
    margin-top: 0.32rem;
    padding-bottom: 0.2rem;
    background: #FFFFFF;
    border-radius: 0.11rem;
    overflow: hidden;

    .business-head {
      margin-top: 0.43rem;
      padding-left: 0.43rem;
      font-size: 0.43rem;
      font-weight: bold;
    }
    .business-body{
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }

    .business-item {
      display: inline-block;
      width: 25%;
      margin-top: 0.3rem;
      text-align: center;

      img {
        width: 1.15rem;
        height: 1.15rem;
      }

      P {
        padding-left: 0;
        font-size: 0.32rem;
        font-weight: 400;
        color: #565656;
        height: 1rem;
      }
    }
  }
}

</style>
