import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

// 获取所有语言文件
const langFiles = require.context('@/language/', false, /.js$/).keys().map(
  i => {
    return i.replace('./', '').split('.')[0]
  })

// 若有对应语言文件则使用，否则用中文
const lan = navigator.language.includes('en') ? 'en-US' : navigator.language.includes('vi') ? 'vi-VN' : 'zh-CN'
const lang = langFiles.includes(lan) ? require(
  `@/language/${[lan]}.js`).default : require(
  `@/language/zh-CN.js`).default

const routes = [
  {
    path: '/',
    name: 'HomeView',
    meta: {
      hiddenHeadBar: true,
      hiddenHeadIcon: true,
      index: 0,
      keepAlive: false
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    meta: { hiddenHeadBar: true, index: -1 },
    component: () => import('@/views/Login')
  },
  {
    path: '/todo',
    name: 'TodoView',
    meta: { title: lang.routerTitle.todoList, index: 1 },
    component: () => import('@/views/TodoView')
  },
  {
    path: '/exceptionHandle',
    name: 'ExceptionHandle',
    meta: { title: lang.andon.exceptionHandle, index: 1, keepAlive: true },
    component: () => import('@/views/andon/handle/index')
  },
  {
    path: '/overtimeException',
    name: 'OvertimeException',
    meta: { title: lang.routerTitle.overTimeList, index: 2, keepAlive: true },
    component: () => import('@/views/andon/handle/OvertimeException')
  },
  {
    path: '/exceptionDetail',
    name: 'ExceptionDetail',
    meta: { title: lang.routerTitle.exceptionDetail, index: 3 },
    component: () => import('@/views/andon/handle/ExceptionDetail')
  },
  {
    path: '/finishHandle',
    name: 'FinishHandle',
    meta: { title: lang.andon.finishHandle, index: 4 },
    component: () => import('@/views/andon/handle/FinishHandle')
  },
  {
    path: '/exceptionTrigger',
    name: 'ExceptionTrigger',
    meta: { title: lang.andon.exceptionTrigger, index: 1 },
    component: () => import('@/views/andon/trigger')
  },
  {
    path: '/reasonApply',
    name: 'ReasonApply',
    meta: { title: lang.andon.reasonApply, index: 1, keepAlive: true },
    component: () => import('@/views/andon/apply')
  },
  {
    path: '/recordDetail',
    name: 'RecordDetail',
    meta: { title: lang.andon.reasonApplyDetail, index: 2 },
    component: () => import('@/views/andon/apply/RecordDetail')
  },
  // kanban系统
  {
    path: '/kanban',
    name: 'Kanban',
    meta: { title: lang.routerTitle.bindKanban, index: 1 },
    component: () => import('@/views/kanban')
  },
  // sim系统
  {
    path: '/sim',
    name: 'Sim',
    meta: { title: lang.homePage.sim, index: 1 },
    component: () => import('@/views/sim')
  },
  // sop系统
  {
    path: '/sop',
    name: 'Sop',
    meta: { title: lang.sop.bindProcedures2, index: 1 },
    component: () => import('@/views/sop')
  },
  // 终端管理
  {
    path: '/device',
    name: 'Device',
    meta: { title: lang.device.bindDevice, index: 1 },
    component: () => import('@/views/device')
  },
  // 设备关机
  {
    path: '/deviceShutdown',
    name: 'DeviceShutdown',
    meta: { title: lang.homePage.deviceShutdown, index: 1 },
    component: () => import('@/views/deviceShutdown')

  },
  {
    path: '/mesControl',
    name: 'MesControl',
    meta: { title: lang.homePage.mesControl, index: 1 },
    component: () => import('@/views/mesControl')
  },
  {
    path: '/productionManage',
    name: 'ProductionManage',
    meta: { title: '生产管理', index: 1 },
    component: () => import('@/views/productionManage')
  },
  // tpm
  {
    path: '/tpm',
    name: 'Tpm',
    meta: { title: lang.tpm.tpmSystem, index: 1 },
    component: () => import('@/views/tpm')
  },
  {
    path: '/query',
    name: 'Query',
    meta: { title: lang.tpm.query, index: 2, keepAlive: true },
    component: () => import('@/views/tpm/query')
  },
  {
    path: '/binding',
    name: 'Binding',
    meta: { title: lang.tpm.bind, index: 2, keepAlive: true },
    component: () => import('@/views/tpm/binding')
  },
  {
    path: '/deviation',
    name: 'Deviation',
    meta: { title: lang.tpm.deviation, index: 2, keepAlive: true },
    component: () => import('@/views/tpm/deviation')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    meta: { title: lang.tpm.maintenance, index: 2, keepAlive: true },
    component: () => import('@/views/tpm/maintenance')
  },
  {
    path: '/spotInspection',
    name: 'SpotInspection',
    meta: { title: lang.tpm.spotInspection, index: 2, keepAlive: true },
    component: () => import('@/views/tpm/spotInspection')
  },
  {
    path: '/scanCodePage',
    name: 'ScanCodePage',
    meta: { title: lang.tpm.ScanCodePage, index: 3, keepAlive: true },
    component: () => import('@/views/tpm/scanCodePage')
  },
  {
    path: '/queryDetail',
    name: 'QueryDetail',
    meta: { title: lang.tpm.queryDetail, index: 3, keepAlive: true },
    component: () => import('@/views/tpm/query/detail')
  },
  {
    path: '/deviationDetail',
    name: 'DeviationDetail',
    meta: { title: lang.tpm.deviationDetail, index: 3, keepAlive: true },
    component: () => import('@/views/tpm/deviation/detail')
  },
  {
    path: '/maintenanceDetail',
    name: 'MaintenanceDetail',
    meta: { title: lang.tpm.maintenanceDetail, index: 3, keepAlive: true },
    component: () => import('@/views/tpm/maintenance/detail')
  },
  {
    path: '/spotInspectionDetail',
    name: 'SpotInspectionDetail',
    meta: { title: lang.tpm.spotInspection, index: 3, keepAlive: true },
    component: () => import('@/views/tpm/spotInspection/detail')
  },
  // 生产开线
  {
    path: '/production',
    name: 'Production',
    meta: { title: lang.homePage.production, index: 1 },
    component: () => import('@/views/production')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !getToken()) {
    next({ path: '/login' })
    return
  }
  next()
})

export default router
