
/* eslint-disable */
export default {
  system: {
    '401': '认证失败，无法访问系统资源',
    '403': '当前操作没有权限',
    '404': '访问资源不存在',
    'default': '系统未知错误，请反馈给管理员'
  },
  routerTitle: {
    workBench: '工作台',
    todoList: '待办事项',
    overTimeList: '超时异常列表',
    exceptionDetail: '异常详情',
    bindKanban: '绑定看板'
  },
  status: {
    handle: '待处理',
    handled: '已处理',
    handling: '处理中',
    approve: '待审批',
    pass: '已通过',
    noPass: '已驳回'
  },
  components: {
    takePhotos: '拍照',
    noChange: '不可修改信息',
    none: '无',
    topType: '一级异常类型',
    later: '数据加载中, 请稍后重试',
    chooseLineInfo: '请选择产线信息',
    chooseWorkshop: '请选择车间',
    factory: '厂区',
    noChildren: '没有下级',
    scanOrAdd: '扫码或点击添加产线',
    addLineInfo: '点击添加产线',
    selectOrder: '选择产线工单',
    startCheck: '开始检查'
  },
  common: {
    loading: '加载中...',
    iGot: '我知道了',
    pleaseInput: '请输入',
    nextStep: '下一步',
    overtime: '已超时',
    requesting: '请求中...',
    cancel: '取消',
    systemName: '智能制造生产信息化管理平台',
    confirm: '确定',
    checkAll: '查看全部',
    noData: '暂无数据',
    noOption: '暂无选项',
    all: '共',
    strip: '条',
    noMore: '没有更多了',
    choose: '请选择',
    uploadImage: '上传图片',
    imageLimit: '不超过10张',
    clickUpload: '点击上传',
    triggerMan: '触发人',
    factory: '工厂',
    shop: '车间',
    workshop: '车间',
    line: '产线',
    productionLine: '产线',
    factoryName: '工厂名称',
    workshopName: '车间名称',
    chooseFactory: '请先选择工厂',
    chooseWorkshop: '请先选择车间',
    lineName: '产线名称',
    handler: '经手人',
    descriptionNotice: '请输入备注说明',
    flow: '流程',
    submit: '提交',
    submitting: '提交中...',
    submitSuccess: '提交成功!',
    submitFail: '提交失败!',
    bindSuccess: '绑定成功!',
    bindFail: '绑定失败!',
    shutdownSuccess: '关机成功!',
    shutdownFail: '关机失败!',
    notice: '提示',
    changeLatter: '数据加载中,请稍后切换'
  },
  login: {
    login: '登 录',
    logging: '登录中...',
    accountNotice: '请输入账号',
    passwordNotice: '请输入密码',
    rememberAccountAndPassword: '记住用户名及密码',
    fail: '获取权限失败',
    bindAccount: '绑定账号',
    accountLogin: '账号密码登录',
    wechatLogin: '企业微信授权登录'
  },
  homePage: {
    todo: '待办',
    productionSystem: '生产系统',
    andonSystem: 'Andon系统',
    sim: 'SIM',
    sop: 'SOP',
    kanban: 'Kanban',
    device: '终端管理',
    tpm: 'TPM系统',
    production: '生产开线',
    checkAndOpen: '开线检测',
    deviceManage: '设备管理',
    deviceShutdown: '设备关机',
    mesControl:'Mes管控',
    productionManage:'生产管理',
  },
  todo: {
    todayTodo: '今日待办'
  },
  andon: {
    scanRightCode: '请扫描设备二维码',
    imageLimit: '图片上传不超过10张',
    applyNew: '原因不匹配, 请申请新的原因',
    handleSuccess: '处理成功 !',
    exception: '异常',
    modifyException: '修改异常',
    handle: '处理',
    exceptionHandle: '异常处理',
    reasonApply: '原因申请',
    reasonApplyDetail: '异常原因申请详情',
    exceptionTrigger: '异常触发',
    exceptionDescription: '异常说明',
    description: '说明',
    applyFlow: '发起流程',
    historyRecords: '历史记录',
    secondType: '次级异常类型',
    exceptionReason: '异常原因',
    chooseExceptionReason: '请选择异常原因',
    reasonNotice: '请输入原因，不超过15字',
    handleDescription: '处理说明',
    descriptionNotice: '请输入处理说明',
    descriptionLimit: '处理说明不得超过200字',
    relatedMission: '关联异常任务',
    chooseAdd: '选择添加',
    flowNotice: '必填信息填写后，自动显示流程',
    noMission: '还没有异常任务哦~',
    copy: '抄送',
    copied: '已抄送',
    people: '人',
    copyPeople: '抄送人',
    typeOrTrigger: '输入异常类型/触发人搜索',
    auditPeople: '审批人',
    auditSuccess: '审批成功',
    searchContent: '输入内容搜索',
    applyReason: '申请的异常原因',
    exceptionType: '异常类型',
    submitReasonApply: '提交的原因申请',
    noPass: '驳回',
    pass: '通过',
    noPassReason: '驳回原因',
    noPassDescription: '驳回说明',
    pleaseNoPass: '请填写驳回说明',
    auditPass: '审批已通过',
    auditNoPass: '审批未通过',
    auditing: '审批中',
    surplusTime: '剩余时间',
    consumeTime: '消耗时间',
    handleTime: '处理用时',
    exceptionInfoFlow: '异常信息流',
    reassignment: '改派',
    reassignmentSuccess: '改派成功',
    chooseReassignment: '请选择改派人',
    chooseAssist: '请选择协助人',
    assist: '协助',
    assistSuccess: '请求协助成功',
    finishAssist: '完成协助',
    upgrade: '升级',
    upgradeSuccess: '升级成功 !',
    upgradeNotice: '升级中, 请稍后操作',
    image: '现场图片',
    closeNotice: '该操作将关闭当前异常，并重新触发一条新的异常记录，请确认是否修改异常？',
    multiChoose: '可多选',
    onlyOne: '仅限选择1人',
    choose: '选择',
    remarkDescription: '备注说明',
    remarkLimit: '备注说明不得超过200字',
    signInSuccess: '签到成功',
    closeSuccess: '关闭成功',
    signing: '立即签到',
    closeException: '关闭异常',
    finishHandle: '完成处理',
    overtimeMission: '超时异常任务',
    handleNow: '立即处理',
    procedures: '异常工序',
    devices: '异常设备',
    materials: '异常物料',
    group: '处理组',
    groupNotice: '根据异常类型自动匹配',
    noGroup: '未查询到处理组',
    noGroup2: '暂未查询到对应的处理组, 请联系管理员处理',
    noDeviceInfo: '没有该设备的产线信息',
    operationMap: {
      create: '创建异常',
      distribute: '分配任务',
      handle: '处理异常',
      up: '升级异常',
      complete: '处理结束',
      close: '关闭异常',
      mistake: '误触',
      overtime: '超时',
      assist: '异常协助'
    }
  },
  device: {
    chooseDeviceType: '选择设备类型',
    bindDevice: '绑定设备',
    scanBind: '扫码绑定',
    code: '序列号',
    bound: '已绑定',
    deviceType: '设备类型',
    bindPosition: '绑定位置',
    deviceNotice: '该设备已在',
    deviceNotice2: '绑定，是否更换 ?',
    workshopOrStore: '车间/仓库',
    noEmpty: '不能为空',
    addDevice: '请添加设备',
    deviceDescription: '设备说明',
    pleaseInputName: '请输入，否则默认为序列号',
    theDeviceAlreadyExists: '该设备已存在',
    shutdownSuccess: '关机成功',
    shutdownFiled: '关机失败',
    controlProcess: '管控工序'
  },
  kanban: {
    chooseKanban: '选择要绑定的看板',
    bindKanban: '请先绑定该设备到Kanban系统'
  },
  sim: {
    attendances: '出勤统计',
    production: '生产质量',
    exception: '异常统计',
    device: '设备报表',
    material: '物料管理'
  },
  sop: {
    noLineInfo: '该设备还未绑定产线信息',
    registerDevice: '请先注册该设备',
    needBind: '所有工序都需要绑定设备',
    chooseProgress: '选择工单',
    chooseMachine: '选择机种',
    pleaseChooseMachine: '请选择机种',
    bindProcedures: '绑定工序',
    changeDevice: '更换设备',
    deviceName: '设备名称',
    bindProcedures2: '工序绑定',
    machineOrOrder: '机种/工单号',
    order: '工单号',
    machine: '机种',
    machineName: '机种名称',
    machineCode: '机种编码',
    product: '生产产品',
    planCount: '计划生产数量',
    planTime: '计划生产时间',
    piece: '件',
    procedures: '工序',
    currentProcedures: '当前工序',
    deviceCode: '设备序列号',
    change: '更换',
    searching: '查询中...',
    chooseProcedures: '选择工序',
    bindInfo: '工序绑定信息',
    pleaseInput: '请输入工单号或选择产线',
    noInfo: '未查询到该机种信息',
    noRelatedOrder: '未查询到相关工单',
    noRelatedProcedure: '未查询到相关工序',
    pleaseChooseSameMachineCode: '请选择相同机种的工单'
  },
  tpm: {
    tpmSystem: 'TPM系统',
    query: '资产查询',
    binding: '资产绑定',
    deviation: '资产异动',
    maintenance: '资产维修',
    spotInspection: '资产点检',
    assetCodeScan: '资产编码扫描',
    ScanCodePage: '资产编码扫描',
    queryDetail: '资产详情',
    assetCode: '资产编码',
    assetName: '资产名称',
    assetLocation: '资产位置',
    assetsOriginalValue: '资产原值',
    assetsResidualValue: '资产残值',
    purchaseDate: '购入日期',
    startDate: '启用日期',
    acceptor: '验收人',
    acceptanceDate: '验收日期',
    userDepartment: '使用部门',
    depreciationDepartment: '折旧部门',
    assetClass: '资产类别',
    assetModel: '资产型号',
    assetType: '资产类型',
    assetPower: '资产功率',
    appearanceSize: '外观尺寸',
    assetStatus: '资产状态',
    maintenanceCycle: '保养周期',
    designCapacity: '设计产能',
    theoreticalRate: '理论直通率',
    assetHistory: '资产履历',
    assetFile: '资产档案',
    deviationDetail: '资产详情',
    maintenanceDetail: '资产维修',
    searchPlaceholder:"输入资产编码或名称查询",
    search:"最近搜索",
    empty:"清空记录",
  },
  production: {
    scanOrAdd: '扫码或点击添加产线',
    selectOrder: '选择产线工单',
    startCheck: '开始检查',
    hasMes: '是否有MES',
    noOrderInfo: '该产线无工单信息',
    planNumber: '计划数量',
    searchCode:"请输入机种编码搜索",
    serach:"搜索",
    openSuccess:"产线已成功开启",
    openLimit:"当前产线不符合生产要求",
    openLineTip:"强制开线可能会导致错误，请再次确认是否要强制开线",
    openLine:'强制开线',
    openLineId:"开线产线",
    open:"开线",
    normalOpen:"正常开线",
    backStep:"暂不开线",
    successMan:"应出勤12人，实际出勤12人",
    successMachine:"机器保养周期，生产参数正常",
    successMaterial:"物料已齐全，满足生产要求",
    successMethod:"流程设置和人员资质完成",
    successMeasurement:"各工序测量设备正常",
    successEnvironment:"温湿度符合生产要求",
    failMan:"人员出勤验证失败",
    failMachine:"机器生产参数异常",
    failMaterial:"缺失部分物料",
    failMethod:"流程设置或人员资质异常",
    failMeasurement:"设备异常",
    failEnvironment:"温湿度不符合生产要求",
    checking:"检查中, 请稍后...",
    checkSuccess:"检查已完成, 符合生产要求",
    checkFail:"检查有异常",
    man: "人员",
    machine: "设备",
    material: "物料",
    method: "方法",
    measurement: "测量",
    environment: "环境",
    temperatureRange:"温度范围",
    temperature:"当前温度",
    humidityRange:"湿度范围",
    humidity:"当前湿度",
    missingMaterial:"缺失物料",
    person:"人",
    shouldBePresent:"应出勤",
    actualAttendance:"实际出勤",
    attendanceLimit:"不满足生产要求，请核验！",
    requireQualification:"未打卡资质",
  }
}
