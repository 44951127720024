import request from '@/utils/request'

// 获取用户详细信息
export function getUserInfo() {
  return request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}

/** 查询生产信息基础数据列表 */
export function listCommonBaseInfoApi(query) {
  return request({
    url: '/tb/commonBaseInfo/list/all',
    method: 'get',
    params: query
  })
}

/**  绑定设备基本信息 */
export function bindBaseInfo(data) {
  return request({
    url: '/tb/device/bindBaseInfo',
    method: 'post',
    data
  })
}

/**  批量修改设备基本信息 */
export function batchUpdateBaseInfo(data) {
  return request({
    url: '/tb/device/batchUpdate',
    method: 'put',
    data
  })
}

/**  查询设备基本信息 */
export function getDeviceInfo(code) {
  return request({
    url: '/tb/device/getDeviceInfo/' + code,
    method: 'get'
  })
}

/**  通过序列号和设备类型获取设备信息 */
export function getInfoByCode(code, type) {
  return request({
    url: `/tb/device/${code}/${type}`,
    method: 'get'
  })
}

/**  通过产线ID获取工单 */
export function getReadyOrderByLineId(lineId) {
  return request({
    url: `/tb/order/listOfReady`,
    method: 'get',
    params: {
      lineId: lineId,
      pageSize: 999
    }
  })
}

/**  通过产线ID获取工单 */
export function getOrderByLineId(lineId) {
  return request({
    url: `/tb/order/list/${lineId}`,
    method: 'get'
  })
}

/**  通过机种编码获取机种信息 */
export function getMachineById(id) {
  return request({
    url: `/tb/material/listByMes/?tbId=${id}`,
    method: 'get'
  })
}

/**  通过工单的机种编号获取工序列表 */
export function getProcedureById(data) {
  return request({
    url: `/tb/procedure/list/${data.id}`,
    method: 'get',
    params: data
  })
}

/**  通过产线查询工序列表 */
export function getProcedureByLineId(lineId) {
  return request({
    url: `/tb/procedure/option`,
    method: 'get',
    params: {
      lineId: lineId
    }
  })
}

/**  通过工序id数组查询物料列表 */
export function getMaterialsByProcedureIds(Ids) {
  return request({
    url: `/tb/material/option`,
    method: 'get',
    params: {
      procedureIds: Ids + ''
    }
  })
}

/**  通过工序id数组查询设备列表 */
export function getDevicesByProcedureIds(Ids) {
  return request({
    url: `/tb/equipment/option`,
    method: 'get',
    params: {
      procedureIds: Ids + ''
    }
  })
}

/**  通过产线ID查询设备列表 */
export function getDevicesByLineId(lineId) {
  return request({
    url: `/tb/equipment/option/byLine`,
    method: 'get',
    params: {
      lineId: lineId + ''
    }
  })
}

/**  设备列表 */
export function getDeviceList(data) {
  return request({
    url: `/tb/device/list`,
    method: 'get',
    params: data
  })
}

/**  待办列表 */
export function todoList(data) {
  return request({
    url: `/tb/processing/list`,
    method: 'get',
    params: data
  })
}

/** 设备关机 */
export function deviceShutdownApi(lineId) {
  return request({
    url: '/tb/device/shutdown/' + lineId,
    method: 'post'
  })
}

/** mes过站管控 */
export function mesControlApi(lineId, data) {
  return request({
    url: '/andon/exception/unfreeze/' + lineId,
    method: 'post',
    data
  })
}
