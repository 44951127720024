import axios from 'axios'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { Toast } from 'vant'
import router from '@/router'
import Cookies from 'js-cookie'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 90000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    Cookies.set('locale', navigator.language.replace('-', '_'))
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200
    // 获取错误信息
    const msg = errorCode[code] || response.data.msg || errorCode['default']
    if (code === 401) {
      Toast('请重新登录')
      router.push('/login')
    } else if (code === 500) {
      Toast(msg)
      return response.data
    } else {
      return response.data
    }
    // }
  },
  error => {
    console.log('err' + error)
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Toast(message)
    return Promise.reject(error)
  }
)

export default service
