<template>
  <div class="status-icon">
    <SvgIcon :icon-class="exceptionStatus[status]" />
  </div>

</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    status: {
      required: true,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      exceptionStatus: {
        undefined: '',
        '0': 'todo',
        '1': 'handle',
        '2': 'already',
        '3': 'close',
        '4': 'overtime', // 签到超时
        '5': 'overtime' // 处理超时
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-icon {
  display: inline-block;
  height: 100%;
  line-height: 100%;
  vertical-align: top;
}
</style>
