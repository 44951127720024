import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/js/vantImport'
import '@/assets/js/remConfig'
import '@/style/index.scss'
import '@/assets/css/font.css'
import * as myUtils from '@/utils/myUtils'
import SvgIcon from '@/components/SvgIcon/index.vue'// svg组件
Vue.component('SvgIcon', SvgIcon)
import '@/components/SvgIcon/index.js'

// 多语言支持
import VueI18n from 'vue-i18n'
// 引入语言包
import cn from './language/zh-CN.js'
import en from './language/en-US.js'
import vi from './language/vi-VN.js'

Vue.use(VueI18n)

// 将方法挂在到Vue实例的原型上
Object.keys(myUtils).forEach(key => {
  Vue.prototype['$' + key] = myUtils[key]
})

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'zh-CN', // 默认语言
  messages: {
    'zh-CN': cn,
    'en-US': en,
    'vi-VN': vi
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
