/* eslint-disable */
export default {
    system: {
      '401': 'Xác thực không thành công, không thể truy cập tài nguyên hệ thống',
      '403': 'Hoạt động hiện tại không có quyền',
      '404': 'tài nguyên truy cập không tồn tại',
      'default': 'Lỗi hệ thống không xác định, vui lòng phản hồi cho quản trị viên'
    },
    routerTitle: {
      workBench: 'bàn làm việc',
      todoList: 'những việc cần làm',
      overTimeList: 'Danh sách các trường hợp ngoại lệ hết thời gian chờ',
      exceptionDetail: 'Chi tiết ngoại lệ',
      bindKanban: 'Ràng buộc Kanban'
    },
    status: {
      handle: 'Chưa giải quyết',
      handled: 'xử lý',
      handling: 'Xử lý',
      approve: 'Chưa giải quyết',
      pass: 'đi qua',
      noPass: 'vật bị loại bỏ'
    },
    components: {
      takePhotos: 'ảnh chụp',
      noChange: 'thông tin không thể sửa đổi',
      none: 'không có',
      topType: 'Loại ngoại lệ hạng nhất',
      later: 'Đang tải dữ liệu, vui lòng thử lại sau',
      chooseLineInfo: 'Vui lòng chọn thông tin dây chuyền sản xuất',
      chooseWorkshop: 'Vui lòng chọn xưởng',
      factory: 'khu vực nhà máy',
      noChildren: 'không cấp dưới',
      scanOrAdd: 'Quét mã hoặc nhấp để thêm dây chuyền sản xuất',
      addLineInfo: 'Bấm để thêm dây chuyền sản xuất',
      selectOrder: 'Chọn thứ tự công việc dây chuyền sản xuất',
      startCheck: 'bắt đầu kiểm tra'
    },
    common: {
      loading: 'Đang tải...',
      iGot: 'tôi hiểu rồi',
      pleaseInput: 'vui lòng nhập',
      nextStep: 'Bước tiếp theo',
      overtime: 'hết giờ',
      requesting: 'Yêu cầu...',
      cancel: 'Hủy bỏ',
      systemName: 'Nền tảng quản lý thông tin sản xuất sản xuất thông minh',
      confirm: 'Chắc chắn',
      checkAll: 'xem tất cả',
      noData: 'Không có dữ liệu',
      noOption: 'không có lựa chọn',
      all: 'chung',
      strip: 'dải',
      noMore: 'không còn nữa',
      choose: 'xin vui lòng chọn',
      uploadImage: 'tải lên hình ảnh',
      imageLimit: 'không quá 10',
      clickUpload: 'Nhấp để tải lên',
      triggerMan: 'người kích hoạt',
      factory: 'nhà máy',
      shop: 'xưởng',
      workshop: 'xưởng',
      line: 'DâyChuyềnSảnXuất',
      productionLine: 'DâyChuyềnSảnXuất',
      factoryName: 'Tên nhà máy',
      workshopName: 'tên xưởng',
      chooseFactory: 'Vui lòng chọn nhà máy đầu tiên',
      chooseWorkshop: 'Vui lòng chọn xưởng trước',
      lineName: 'Tên dây chuyền sản xuất',
      handler: 'Người buôn bán',
      descriptionNotice: 'Vui lòng nhập ghi chú',
      flow: 'quá trình',
      submit: 'nộp',
      submitting: 'nộp...',
      submitSuccess: 'Gửi thành công!',
      submitFail: 'Gửi không thành công!',
      bindSuccess: 'Ràng buộc đã thành công!',
      bindFail: 'Liên kết không thành công!',
      shutdownSuccess: 'Tắt máy thành công!',
      shutdownFail: 'Tắt máy không thành công!',
      notice: 'gợi ý',
      changeLatter: 'Đang tải dữ liệu, vui lòng chuyển đổi sau'
    },
    login: {
      login: 'Đăng nhập',
      logging: 'đăng nhập...',
      accountNotice: 'Vui lòng nhập tên người dùng',
      passwordNotice: 'xin vui lòng nhập mật khẩu',
      rememberAccountAndPassword: 'Ghi nhớ tên người dùng và mật khẩu',
      fail: 'Truy cập không thành công',
      bindAccount: 'Ràng buộc tài khoản',
      accountLogin: 'Mật khẩu tài khoản đăng nhập',
      wechatLogin: 'Doanh nghiệp ứng dụng ủy quyền đăng nhập'
    },
    homePage: {
      todo: 'Sắp tới',
      productionSystem: 'hệ thống sản xuất',
      andonSystem: 'hệ thống Andon',
      sim: 'SIM',
      sop: 'SOP',
      kanban: 'Kanban',
      device: 'quản lý thiết bị đầu cuối',
      tpm: 'hệ thống TPM',
      production: 'bắt đầu sản xuất',
      checkAndOpen: 'Phát hiện dòng mở',
      deviceManage: 'quản lý thiết bị',
      deviceShutdown: 'tắt thiết bị',
      mesControl:'Kiểm soát MES.',
      productionManage:'Quản lý sản xuất',
    },
    todo: {
      todayTodo: 'để làm ngày hôm nay'
    },
    andon: {
      scanRightCode: 'Vui lòng quét mã QR của thiết bị',
      imageLimit: 'Tải lên không quá 10 ảnh',
      applyNew: 'Lý do không khớp, vui lòng yêu cầu lý do mới',
      handleSuccess: 'Đã xử lý thành công!',
      exception: 'bất thường',
      modifyException: 'sửa đổi ngoại lệ',
      handle: 'đối phó với',
      exceptionHandle: 'xử lý ngoại lệ',
      reasonApply: 'lý do để áp dụng',
      reasonApplyDetail: 'Chi tiết ứng dụng nguyên nhân bất thường',
      exceptionTrigger: 'kích hoạt ngoại lệ',
      exceptionDescription: 'Mô tả ngoại lệ',
      description: 'Minh họa',
      applyFlow: 'Bắt đầu quy trình',
      historyRecords: 'hồ sơ lịch sử',
      secondType: 'Loại ngoại lệ phụ',
      exceptionReason: 'Bất thường',
      chooseExceptionReason: 'Vui lòng chọn lý do ngoại lệ',
      reasonNotice: 'Vui lòng nhập lý do, không quá 15 ký tự',
      handleDescription: 'hướng dẫn xử lý',
      descriptionNotice: 'Vui lòng nhập mô tả xử lý',
      descriptionLimit: 'Hướng dẫn xử lý không được vượt quá 200 từ',
      relatedMission: 'Nhiệm vụ ngoại lệ liên quan',
      chooseAdd: 'chọn thêm',
      flowNotice: 'Sau khi điền các thông tin cần thiết, quá trình sẽ được hiển thị tự động',
      noMission: 'Không có nhiệm vụ bất thường nào ~~',
      copy: 'cc',
      copied: "CC'd",
      people: 'mọi người',
      copyPeople: 'cc',
      typeOrTrigger: 'Nhập loại ngoại lệ/kích hoạt tìm kiếm',
      auditPeople: 'Người phê duyệt',
      auditSuccess: 'Phê duyệt thành công',
      searchContent: 'Nhập tìm kiếm',
      applyReason: 'Lý do bất thường cho ứng dụng',
      exceptionType: 'loại ngoại lệ',
      submitReasonApply: 'Gửi lý do ứng dụng',
      noPass: 'từ chối',
      pass: 'vượt qua',
      noPassReason: 'Lý do từ chối',
      noPassDescription: 'tuyên bố từ chối',
      pleaseNoPass: 'Vui lòng điền vào tuyên bố từ chối',
      auditPass: 'Phê duyệt thông qua',
      auditNoPass: 'Phê duyệt không thành công',
      auditing: 'Sự chấp thuận',
      surplusTime: 'thờiGianCònLại',
      consumeTime: 'mất thời gian',
      handleTime: 'Thời gian xử lý',
      exceptionInfoFlow: 'luồngThôngTinBấtThường',
      reassignment: 'chỉ định lại',
      reassignmentSuccess: 'Tái chỉ định thành công',
      chooseReassignment: 'Vui lòng chọn người chỉ định lại',
      chooseAssist: 'Vui lòng chọn người trợ giúp',
      assist: 'hỗ trợ',
      assistSuccess: 'Yêu cầu hỗ trợ thành công',
      finishAssist: 'hỗ trợ đầy đủ',
      upgrade: 'nângCấp',
      upgradeSuccess: 'cập nhật thành công!',
      upgradeNotice: 'Đang nâng cấp, vui lòng thao tác sau',
      image: 'hình ảnh sống',
      closeNotice: 'Thao tác này sẽ đóng ngoại lệ hiện tại và kích hoạt lại một bản ghi ngoại lệ mới. Vui lòng xác nhận xem có sửa đổi ngoại lệ hay không?',
      multiChoose: 'Nhiều lựa chọn',
      onlyOne: 'Chỉ chọn 1 người',
      choose: 'chọn',
      remarkDescription: 'Cẩm nang hướng dẫn',
      remarkLimit: 'Nhận xét không được vượt quá 200 từ',
      signInSuccess: 'Đăng nhập thành công',
      closeSuccess: 'đã đóng thành công',
      signing: 'đăng nhập ngay',
      closeException: 'đóng ngoại lệ',
      finishHandle: 'xử lý hoàn chỉnh',
      overtimeMission: 'Nhiệm vụ ngoại lệ hết thời gian chờ',
      handleNow: 'đối phó với ngay lập tức',
      procedures: 'quá trình bất thường',
      devices: 'thiết bị bất thường',
      materials: 'vật liệu bất thường',
      group: 'nhóm điều trị',
      groupNotice: 'Kết hợp tự động dựa trên loại ngoại lệ',
      noGroup: 'Không tìm thấy nhóm xử lý',
      noGroup2: 'Nhóm xử lý tương ứng chưa được truy vấn, vui lòng liên hệ quản trị viên để xử lý',
      noDeviceInfo: 'Không có thông tin dây chuyền sản xuất cho thiết bị này',
      operationMap: {
        create: 'tạo ngoại lệ',
        distribute: 'bài tập',
        handle: 'xử lý ngoại lệ',
        up: 'nâng cấp ngoại lệ',
        complete: 'kết thúc xử lý',
        close: 'đóng ngoại lệ',
        mistake: 'chạm nhầm',
        overtime: 'hết giờ',
        assist: 'hỗ trợ bất thường'
      }
    },
    device: {
      chooseDeviceType: 'Chọn loại thiết bị',
      bindDevice: 'thiết bị liên kết',
      scanBind: 'quét mã ràng buộc',
      code: 'số seri',
      bound: 'ràng buộc',
      deviceType: 'Loại thiết bị',
      bindPosition: 'vị trí ràng buộc',
      deviceNotice: 'Thiết bị đã ở trong',
      deviceNotice2: 'Ràng buộc, bạn có muốn thay thế?',
      workshopOrStore: 'xưởng/kho',
      noEmpty: 'Không thể để trống',
      addDevice: 'Vui lòng thêm thiết bị',
      deviceDescription: 'Mô tả thiết bị',
      pleaseInputName: 'Vui lòng nhập, nếu không thì mặc định là số sê-ri',
      theDeviceAlreadyExists: 'Thiết bị đã tồn tại',
      shutdownSuccess: 'Tắt máy thành công',
      shutdownFiled: 'Tắt máy không thành công',
      controlProcess: 'Quy trình kiểm soát'
    },
    kanban: {
      chooseKanban: 'Chọn Kanban để ràng buộc',
      bindKanban: 'Vui lòng liên kết thiết bị với hệ thống Kanban trước'
    },
    sim: {
      attendances: 'thống kê chuyên cần',
      production: 'chất lượng sản xuất',
      exception: 'thống kê bất thường',
      device: 'Báo cáo thiết bị',
      material: 'quản lý vật tư'
    },
    sop: {
      noLineInfo: 'Thiết bị chưa bị ràng buộc với thông tin dây chuyền sản xuất',
      registerDevice: 'Vui lòng đăng ký thiết bị trước',
      needBind: 'Tất cả các quy trình cần phải ràng buộc thiết bị',
      chooseProgress: 'chọn thứ tự công việc',
      chooseMachine: 'Chọn mô hình',
      pleaseChooseMachine: 'Vui lòng chọn một mô hình',
      bindProcedures: 'quá trình ràng buộc',
      changeDevice: 'Thay thế thiết bị',
      deviceName: 'Tên thiết bị',
      bindProcedures2: 'Quy trình ràng buộc',
      machineOrOrder: 'Số mẫu/đơn đặt hàng công việc',
      order: 'Số đơn hàng công việc',
      machine: 'người mẫu',
      machineName: 'Tên mẫu',
      machineCode: 'mẫu mã',
      product: 'sản phẩm',
      planCount: 'Số lượng sản xuất theo kế hoạch',
      planTime: 'kế hoạch thời gian sản xuất',
      piece: 'miếng',
      procedures: 'quá trình',
      currentProcedures: 'quá trình hiện tại',
      deviceCode: 'nghĩ ra số sê-ri',
      change: 'thay thế',
      searching: 'Cuộc điều tra...',
      chooseProcedures: 'Chọn quy trình',
      bindInfo: 'Thông tin ràng buộc quy trình',
      pleaseInput: 'Vui lòng nhập số thứ tự sản xuất hoặc chọn một dây chuyền sản xuất',
      noInfo: 'Không tìm thấy thông tin mô hình',
      noRelatedOrder: 'Không tìm thấy đơn đặt hàng công việc có liên quan',
      noRelatedProcedure: 'Không tìm thấy quá trình liên quan',
      pleaseChooseSameMachineCode: 'ui lòng chọn thứ tự làm việc của cùng một mô hình'
    },
    tpm: {
      tpmSystem: 'hệ thống TPM',
      query: 'Truy vấn tài sản',
      binding: 'ràng buộc tài sản',
      deviation: 'thay đổi tài sản',
      maintenance: 'bảo trì tài sản',
      spotInspection: 'kiểm tra tài sản',
      assetCodeScan: 'Quét mã tài sản',
      ScanCodePage: 'Quét mã tài sản',
      queryDetail: 'Chi tiết nội dung',
      assetCode: 'mã tài sản',
      assetName: 'Tên tài sản',
      assetLocation: 'vị trí tài sản',
      assetsOriginalValue: 'giá trị ban đầu của tài sản',
      assetsResidualValue: 'Giá trị còn lại của tài sản',
      purchaseDate: 'ngày mua',
      startDate: 'ngày kích hoạt',
      acceptor: 'Người chấp nhận',
      acceptanceDate: 'ngày chấp nhận',
      userDepartment: 'bộ phận sử dụng',
      depreciationDepartment: 'bộ phận khấu hao',
      assetClass: 'Loại tài sản',
      assetModel: 'mô hình tài sản',
      assetType: 'loại tài sản',
      assetPower: 'sức mạnh tài sản',
      appearanceSize: 'kích thước vật lý',
      assetStatus: 'tình trạng tài sản',
      maintenanceCycle: 'chu kỳ bảo trì',
      designCapacity: 'Công suất thiết kế',
      theoreticalRate: 'Tỷ lệ đậu lý thuyết',
      assetHistory: 'lịch sử tài sản',
      assetFile: 'tệp tài sản',
      deviationDetail: 'Chi tiết nội dung',
      maintenanceDetail: 'bảo trì tài sản',
      searchPlaceholder:"Nhập mã tài sản hoặc truy vấn tên",
      search:"Tìm kiếm gần đây",
      empty:"Dọn sạch hồ sơ",
    },
    production: {
      scanOrAdd: 'Quét mã hoặc nhấp để thêm dây chuyền sản xuất',
      selectOrder: 'Chọn thứ tự công việc dây chuyền sản xuất',
      startCheck: 'bắt đầu kiểm tra',
      hasMes: 'Có MES không',
      noOrderInfo: 'Dây chuyền sản xuất này không có thông tin lệnh sản xuất',
      planNumber: 'Số kế hoạch',
      searchCode:"Hãy nhập tìm kiếm mã hóa máy",
      serach:"Tìm kiếm",
      openSuccess:"Dây chuyền sản xuất đã được mở thành công",
      openLimit:"Dòng sản xuất hiện tại không đáp ứng các yêu cầu sản xuất",
      openLineTip:"Bắt buộc mở dây có thể dẫn đến lỗi, xin vui lòng xác nhận lại cho dù bắt buộc mở dây",
      openLine:'Bắt buộc mở dây',
      openLineId:"Mở đường dây",
      open:"KaiXian",
      normalOpen:"Bình thường mở dây",
      backStep:"Tạm thời không mở đường",
      successMan:"Nên là 12 người, thực sự là 12 người",
      successMachine:"Chu kỳ bảo trì máy, thông số sản xuất bình thường",
      successMaterial:"Vật liệu đã được hoàn thành, đáp ứng các yêu cầu sản xuất",
      successMethod:"Quá trình thiết lập và trình độ nhân sự hoàn thành",
      successMeasurement:"Thiết bị đo lường hoạt động bình thường",
      successEnvironment:"Nhiệt độ và độ ẩm đáp ứng các yêu cầu sản xuất",
      failMan:"Sự xác nhận không thành công",
      failMachine:"Thông số bất thường của máy sản xuất",
      failMaterial:"Thiếu một phần vật liệu",
      failMethod:"Thiết lập tiến trình hoặc trình độ nhân sự bất thường",
      failMeasurement:"Thiết bị bất thường",
      failEnvironment:"Nhiệt độ và độ ẩm không đáp ứng các yêu cầu sản xuất",
      checking:"Đang kiểm tra, xin vui lòng chờ …",
      checkSuccess:"Kiểm tra đã hoàn thành, đáp ứng các yêu cầu sản xuất",
      checkFail:"Kiểm tra bất thường",
      man: "Nhân viên",
      machine: "Thiết bị",
      material: "cùng",
      method: "Phương pháp",
      measurement: "Đo đạc",
      environment: "Môi trường",
      temperatureRange:"Phạm vi nhiệt độ",
      temperature:"Nhiệt độ",
      humidityRange:"Độ ẩm trong phạm vi",
      humidity:"Độ ẩm",
      missingMaterial:"Vật liệu bị mất",
      person:"người",
      shouldBePresent:"Nên tham gia",
      actualAttendance:"Tham gia thực tế",
      attendanceLimit:"Không đáp ứng các yêu cầu sản xuất, xin vui lòng kiểm tra!",
      requireQualification:"Không có bằng cấp",
    }
  }
