/* eslint-disable */
export default {
  system: {
    '401': 'Authentication failed, unable to access system resources',
    '403': 'The current operation does not have permission',
    '404': 'The accessed resource does not exist',
    'default': 'Unknown system error, please feed back to the administrator'
  },
  routerTitle: {
    workBench: 'WorkBench',
    todoList: 'To-do list',
    overTimeList: 'Timeout Exception List',
    exceptionDetail: 'Exception Detail',
    bindKanban: 'binding Kanban'
  },
  status: {
    handle: 'Pending',
    handled: 'Processed',
    handling: 'Processing',
    approve: 'Approval Pending',
    pass: 'Passed',
    noPass: 'Rejected'
  },
  components: {
    takePhotos: 'photograph',
    noChange: 'Non modifiable information',
    none: 'none',
    topType: 'First level',
    later: 'please try later',
    chooseLineInfo: 'Please select',
    chooseWorkshop: 'Please select workshop',
    factory: 'factory',
    noChildren: 'No subordinate',
    scanOrAdd: 'Scan code or click to add production line',
    addLineInfo: 'click to add production line',
    selectOrder: 'Select order',
    startCheck: 'Start check'
  },
  common: {
    loading: 'loading...',
    iGot: 'I got it',
    pleaseInput: 'Please enter',
    nextStep: 'Next step',
    overtime: 'Timed out',
    requesting: 'Requesting...',
    cancel: 'Cancel',
    systemName: 'Intelligent Manufacturing Production Information Management Platform',
    confirm: 'Confirm',
    checkAll: 'View All',
    noData: 'No data',
    noOption: 'No option',
    all: '共',
    strip: '条',
    inTotal: 'In total',
    noMore: 'No more',
    choose: 'please select',
    uploadImage: 'Upload pictures',
    imageLimit: 'No more than 10',
    clickUpload: 'Click',
    triggerMan: 'Trigger',
    factory: 'Factory',
    shop: 'Shop',
    workshop: 'Workshop',
    line: 'Line',
    productionLine: 'Production line',
    factoryName: 'Factory',
    workshopName: 'Workshop',
    chooseFactory: 'Please select a factory first',
    chooseWorkshop: 'Please select a workshop first',
    lineName: 'Production line',
    handler: 'Handler',
    descriptionNotice: 'Please enter a note description',
    flow: 'Technological process',
    submit: 'Submit',
    submitting: 'Submitting...',
    submitSuccess: 'Submission succeeded!',
    submitFail: 'Submission failed!',
    bindSuccess: 'Binding succeeded!',
    bindFail: 'Binding failed!',
    shutdownSuccess: 'Shutdown succeeded!',
    shutdownFail: 'Shutdown failed!',
    notice: 'Tips',
    changeLatter: 'Data loading, please switch later'
  },
  login: {
    login: 'Sign in',
    logging: 'Sign in...',
    accountNotice: 'Please enter an account',
    passwordNotice: 'Please input a password',
    rememberAccountAndPassword: 'Remember account and password',
    fail: 'Failed to obtain permissions',
    bindAccount: 'Bind account',
    accountLogin: 'Account password login',
    wechatLogin: 'Enterprise WeChat authorized login'
  },
  homePage: {
    todo: 'Todo',
    productionSystem: 'Production system',
    andonSystem: 'Andon system',
    sim: 'SIM',
    sop: 'SOP',
    kanban: 'Kanban',
    device: 'Device',
    tpm: 'TPM',
    production: 'Production',
    checkAndOpen: 'check and open',
    deviceManage: 'device manage',
    deviceShutdown: 'device shutdown',
    mesControl:'Mes control',
    productionManage:'Production',
  },
  todo: {
    todayTodo: 'To-do today'
  },
  andon: {
    scanRightCode: 'Please scan the QR code of the device',
    imageLimit: 'Upload no more than 10 pictures',
    applyNew: 'Reason mismatch, apply for new reason',
    handleSuccess: 'Handling succeeded !',
    exception: 'Exception',
    modifyException: 'Modify Exception',
    handle: 'Handle',
    exceptionHandle: 'Exception Handling',
    reasonApply: 'Application Reasons',
    reasonApplyDetail: 'Detail of application',
    exceptionTrigger: 'Exception Trigger',
    exceptionDescription: 'Exception Description',
    description: 'Description',
    applyFlow: 'Initiation process',
    historyRecords: 'History',
    secondType: 'Secondary',
    exceptionReason: 'Exception Reason',
    chooseExceptionReason: 'Please select Exception Reason',
    reasonNotice: 'Please enter a reason, no more than 15 words',
    handleDescription: 'Handling  Description',
    descriptionNotice: 'Please enter the description',
    descriptionLimit: 'Handling description no more than 200 words',
    relatedMission: 'Associate Exception Tasks',
    chooseAdd: 'Add',
    flowNotice: 'Automatically display process when required information is filled in',
    noMission: 'No mission yet~',
    copy: 'Cc',
    copied: 'Cc',
    people: '',
    copyPeople: 'Cc',
    typeOrTrigger: 'Enter Exception Type/Trigger Search',
    auditPeople: 'Approver',
    auditSuccess: 'Approval succeeded',
    searchContent: 'Enter search content',
    applyReason: ' application',
    exceptionType: 'Exception type',
    submitReasonApply: ' application',
    noPass: 'Reject',
    pass: 'Adopt',
    noPassReason: 'Reason for rejection',
    noPassDescription: 'Rejection Note',
    pleaseNoPass: 'Please enter rejection note',
    auditPass: 'Approved',
    auditNoPass: 'Approval failed',
    auditing: 'Approving',
    surplusTime: 'Remaining Time',
    consumeTime: 'Consume Time',
    handleTime: 'Processing Time',
    exceptionInfoFlow: 'Info Flow',
    reassignment: 'Reassign',
    reassignmentSuccess: 'Reassign succeeded',
    chooseReassignment: 'Select a reassigner',
    chooseAssist: 'Select Assistant',
    assist: 'Assist',
    assistSuccess: 'Request for assistance succeeded',
    finishAssist: 'Complete Assistance',
    upgrade: 'Upgrade',
    upgradeSuccess: 'Upgrade succeeded',
    upgradeNotice: 'Upgrading, please operate later',
    image: 'Live Pictures',
    closeNotice: 'This operation will close the current exception and trigger a new exception record again. Please confirm whether to modify the exception？',
    multiChoose: 'Multiple choices',
    onlyOne: 'Only one person can be selected',
    choose: 'Select',
    remarkDescription: 'Note Description',
    remarkLimit: 'Description no more than 200 words',
    signInSuccess: 'Sign in succeeded',
    closeSuccess: 'Close succeeded',
    signing: 'Sign in immediately',
    closeException: 'Close Exception',
    finishHandle: 'Finish processing',
    overtimeMission: 'Timeout Exception Task',
    handleNow: 'Handle immediately',
    procedures: 'Process',
    devices: 'Devices',
    materials: 'Materials',
    group: 'Group',
    groupNotice: 'Automatically match based on exception type',
    noGroup: 'No group found',
    noGroup2: 'No group found,please contact the administrator',
    noDeviceInfo: 'No production line information of the device',
    operationMap: {
      create: 'Create Exception',
      distribute: 'Assign Tasks',
      handle: 'Handling Exception',
      up: 'Upgrade Exception',
      complete: 'Complete',
      close: 'Close Exception',
      mistake: 'Touch by mistake',
      overtime: 'Timed out',
      assist: 'Exception Assistance'
    }
  },
  device: {
    chooseDeviceType: 'Select device type',
    bindDevice: 'Bind device',
    scanBind: 'Scan QR',
    code: 'serial number',
    bound: 'Bound',
    deviceType: 'Device Type',
    bindPosition: 'Binding location',
    deviceNotice: 'The device is already in',
    deviceNotice2: '，replace ?',
    workshopOrStore: 'workshop/warehouse',
    noEmpty: 'Cannot be empty',
    addDevice: 'Please add a device',
    deviceDescription: 'Device Description',
    pleaseInputName: 'Please input，otherwise, it defaults to the serial number',
    theDeviceAlreadyExists: 'The device already exists',
    shutdownSuccess: 'shutdown success',
    shutdownFiled: 'shutdown filed',
    controlProcess: 'control process'
  },
  kanban: {
    chooseKanban: 'Select the Kanban to bind',
    bindKanban: 'Please bind the device to the Kanban system first'
  },
  sim: {
    attendances: 'Attendance statistics',
    production: 'Production quality',
    exception: 'Abnormal statistics',
    device: 'Equipment report',
    material: 'material management'
  },
  sop: {
    noLineInfo: 'The device has not been bound with production line information',
    registerDevice: 'Please register the device first',
    needBind: 'All processes need to be bound with device',
    chooseProgress: 'Select Work Order',
    chooseMachine: 'Select Machine type',
    pleaseChooseMachine: 'Select Machine type',
    bindProcedures: 'Binding operation',
    bindProcedures2: 'Binding operation',
    changeDevice: 'Replace device',
    deviceName: 'Device name',
    machineOrOrder: 'Machine/Work order',
    order: 'number',
    machine: 'Machine type',
    machineName: 'Machine type name',
    machineCode: 'Machine type code',
    product: 'Product',
    planCount: 'Planned production quantity',
    planTime: 'Planned production time',
    piece: 'Piece',
    procedures: 'Operation',
    currentProcedures: 'Current Operation',
    deviceCode: 'Device serial number',
    change: 'Replace',
    searching: 'Searching...',
    chooseProcedures: 'Select operation',
    bindInfo: 'Operation Binding Information',
    pleaseInput: 'Please enter work order number or select production line',
    noInfo: 'The machine type information is not found',
    noRelatedOrder: 'No related work order is found',
    noRelatedProcedure: 'No related procedures is found',
    pleaseChooseSameMachineCode: 'Please select a work order with the same machine number'
  },
  tpm: {
    tpmSystem: 'TPM system',
    query: 'Device query',
    binding: 'Device binding',
    deviation: 'Equipment deviation',
    maintenance: 'Equipment maintenance',
    spotInspection: 'Equipment spot inspection',
    assetCodeScan: 'Asset code scan',
    ScanCodePage: 'Asset code scan',
    queryDetail: 'Asset details',
    assetCode: 'Asset code',
    assetName: 'Asset name',
    assetLocation: 'Asset location',
    assetsOriginalValue: 'Original value of assets',
    assetsResidualValue: 'Residual value of assets',
    purchaseDate: 'Date of purchase',
    startDate: 'Start date',
    acceptor: 'acceptor',
    acceptanceDate: 'Date of acceptance',
    userDepartment: 'User department',
    depreciationDepartment: 'Depreciation department',
    assetClass: 'Class of asset',
    assetModel: 'asset model',
    assetType: 'Type of asset',
    assetPower: 'Asset power',
    appearanceSize: 'Appearance size',
    assetStatus: 'Asset status',
    maintenanceCycle: 'Maintenance cycle',
    designCapacity: 'Design capacity',
    theoreticalRate: 'Theoretical pass-through rate',
    assetHistory: 'Asset history',
    assetFile: 'Asset file',
    deviationDetail: 'Asset details',
    maintenanceDetail: 'Asset maintenance',
    searchPlaceholder:"Enter the asset code or name query",
    search:"Recent search",
    empty:"invalid code record",
  },
  production: {
    scanOrAdd: 'Scan code or click to add production line',
    selectOrder: 'Select order',
    startCheck: 'Start check',
    hasMes: 'has MES',
    noOrderInfo: 'no order info',
    planNumber: 'plan number',
    searchCode:"Please enter model code search",
    serach:"serach",
    openSuccess:"The production line has been successfully opened",
    openLimit:"The current production line does not meet the production requirements",
    openLineTip:"Forcing the cable to open may cause an error. Please confirm whether to force the cable to open again",
    openLine:'Forced opening',
    openLineId:"Open the production line",
    open:"open line",
    normalOpen:"Normal opening",
    backStep:"Hold the line for now",
    successMan:"12 people were supposed to be there, 12 people were actually there",
    successMachine:"Machine maintenance cycle, production parameters are normal",
    successMaterial:"The materials are complete and meet the production requirements",
    successMethod:"Process setup and personnel qualification completed",
    successMeasurement:"The measuring equipment of each process is normal",
    successEnvironment:"Temperature and humidity meet production requirements",
    failMan:"Personnel attendance verification failed",
    failMachine:"The machine production parameters are abnormal",
    failMaterial:"Missing part material",
    failMethod:"Process Settings or personnel qualifications are abnormal",
    failMeasurement:" equipment exception",
    failEnvironment:"Temperature and humidity do not meet production requirements",
    checking:"Checking, please wait...",
    checkSuccess:"The inspection has been completed and meets the production requirements",
    checkFail:"Check for anomalies",
    man: "man",
    machine: "machine",
    material: "material",
    method: "method",
    measurement: "measurement",
    environment: "environment",
    temperatureRange:"temperature range",
    temperature:"temperature",
    humidityRange:"humidity range",
    humidity:"humidity",
    missingMaterial:"missing material",
    person:"person",
    shouldBePresent:"should be present",
    actualAttendance:"actual attendance",
    attendanceLimit:"Does not meet the production requirements, please check！",
    requireQualification:"Qualification for not checking in",
  }
}
